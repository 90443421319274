<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Nouveau Modèle </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'modelList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">

                        <div class="field" :class="{'error': $v.model.id_pere.$error}">

                            <label>Marque</label>
                            <select v-model="model.id_pere" @change="$v.model.id_pere.$touch()"
                                    @blur="$v.model.id_pere.$touch()">
                                <option v-for="marque in marque_list" :value="marque._id">  {{ marque.label }} </option>
                            </select>

                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.model.id_pere.$error">
                                <p> <i class="info circle icon"></i> La marque est Obligatoire. </p>
                            </div>

                        </div>

                        <div class="field">
                            <label>Type</label>
                            <select v-model="model.type_model">
                                <option value="CAMION"> Camion </option>
                                <option value="MOTO"> Moto </option>
                                <option value="VOITURE"> Voiture </option>
                                <option value="AUTRE"> Autre </option>
                            </select>
                        </div>
                        <div class="field" :class="{'error': $v.model.id_pere.$error}">

                            <label>Label</label>
                            <input type="text" placeholder="Label" v-model.trim="$v.model.label.$model" v-uppercase>
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.model.label.$error">
                                <p> <i class="info circle icon"></i> Label est Obligatoire. </p>
                            </div>

                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "modelCrud",
        props:{
            current_model: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              model: {
                  label: '',
                  type_model: 'moteur',
                  type: 'model',
                  id_pere: ''
              },
              marque_list: [],
              selected_marque: {},
          }
        },
        validations: {
            model: {
                label: {required},
                id_pere: {required}
            }
        },
        methods:{
            getMarqueList(){
                log('getMarques');
                const data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {
                        type: 'marque'
                    },
                    options: {
                        page: 1,
                        limit: -1,
                        sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.marque_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            save(){
                log('save model:', this.model);
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: this.model
                };
                if(this.isEdit){ // update
                    let model = JSON.parse(JSON.stringify(this.model));
                    delete model._id;
                    data.query = {
                        _id: this.model._id
                    };
                    data.update = {'$set': model}
                }
                let url = this.isEdit ? this.$SHARED.services.updateIfNotExist
                    : this.$SHARED.services.insertIfNotExist;

                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'modelList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.model[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "Le modèle " + this.$SHARED.messages["7"]
                    }
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {
                        _id: this.model._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'modelList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.model.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        },
        mounted() {
            this.getMarqueList();
            if(this.current_model.hasOwnProperty('_id')){
                this.isEdit = true;
                this.model = JSON.parse(JSON.stringify(this.current_model));
            }
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>